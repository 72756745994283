import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
    confirmCoApplicantKycDetails,
    digiLockerKycStatus,
    getCoApplicantDetails,
    initiateDigiLockerKyc,
} from "../../apis/coApplicant";
import { showToast } from "../../utils/toast";
import { toastTypes } from "../../utils/reusableConst";
import { UploadProofDetails } from "../../types";
import { UPLOAD_AADHAR_STATE } from "./constant";

const useGetVerifyDetails = ({
    setCoApplicantDetails,
    coApplicantDetails,
}: {
    setCoApplicantDetails: Dispatch<SetStateAction<any>>;
    coApplicantDetails: any;
}) => {
    const [showSuccessPage, setShowSuccessPage] = useState(false);
    const [termsConditionCheck, toggleTermsConditionCheck] = useState(false);
    const [validationError, setValidationError] = useState(false);
    const [popUpState, setPopUpState] = useState<Window | null>(null);
    const [digilockerStatusLoading, setDigilockerStatusLoading] =
        useState<boolean>(false);
    const [openDLLoading, setOpenDLLoading] = useState<boolean>(false);
    const [showAadharModal, setShowAadharModal] = useState<boolean>(false);
    const [uploadAadharState, setUploadAadharState] =
        useState<UPLOAD_AADHAR_STATE>();

    const navigate = useNavigate();

    const getCoApplicantDetailsFn = async () => {
        let response = await getCoApplicantDetails();
        response = {
            ...response?.loanApplicantsdata,
            loanAmount: parseFloat(
                response?.loanApplicantsdata?.loanAmount
            ).toFixed(2),
            name:
                response?.loanApplicantsdata.maainApplicantName?.name ||
                response?.loanApplicantsdata?.companyName,
            aadhaarName: response?.loanApplicantsdata?.aadhaarDetails
                ? response?.loanApplicantsdata?.aadhaarName
                : response?.loanApplicantsdata?.coApplicantName,
        };
        setCoApplicantDetails(response);
    };

    useEffect(() => {
        getCoApplicantDetailsFn();
    }, [navigate]);

    useEffect(() => {}, [digilockerStatusLoading]);

    async function checkDigiLockerStatus() {
        try {
            setDigilockerStatusLoading(true);
            const response = await digiLockerKycStatus();
            if (response) {
                setDigilockerStatusLoading(false);
                getCoApplicantDetailsFn();
                if (response?.data?.data?.status === "FAILED") {
                    showToast("Aadhar verification failed", toastTypes.error);
                }
            }
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        let popupChecker: number | undefined; // Explicitly typing as 'number'

        // Check if the popup is closed
        if (popUpState) {
            popupChecker = window.setInterval(async () => {
                if (popUpState.closed) {
                    checkDigiLockerStatus();
                    setPopUpState(null);
                    setShowAadharModal(false);
                }
            }, 1000); // Check every 500ms if the popup is closed
        }

        // Clean up the interval when component unmounts
        return () => {
            if (popupChecker !== undefined) clearInterval(popupChecker);
        };
    }, [popUpState]);

    const openDigiLockerPopup = async () => {
        try {
            setOpenDLLoading(true);
            let response = await initiateDigiLockerKyc();
            if (response?.data?.success) {
                setOpenDLLoading(false);
                const popup = window.open(
                    response?.data?.data?.redirectUrl,
                    "_blank"
                );
                setPopUpState(popup);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setOpenDLLoading(false);
            setShowAadharModal(false);
        }
    };

    const confirmKycDetails = async () => {
        let response = await confirmCoApplicantKycDetails();
        if (response?.success) {
            setShowSuccessPage(true);
            setValidationError(false);
        } else {
            showToast("Failed to submit details", toastTypes.error);
        }
    };

    const uploadProofMappings: UploadProofDetails[] = [
        {
            title: "Upload PAN",
            icon: "/assets/icons/pan-icon.svg",
            isCompleted:
                coApplicantDetails?.panDetails &&
                !coApplicantDetails?.isRejected,
            onClick: () => navigate("/pan-details"),
            isDisabled: false,
            isRejected: false,
        },
        {
            title: "Upload Aadhaar",
            icon: "/assets/icons/aadhar-icon.svg",
            isCompleted: coApplicantDetails?.aadhaarDetails,
            onClick: () => setShowAadharModal(true),
            isDisabled: !coApplicantDetails?.panDetails || openDLLoading,
            isRejected: coApplicantDetails?.isRejected,
        },
        {
            title: "Liveness Check",
            icon: "/assets/icons/camera-icon.svg",
            isCompleted:
                coApplicantDetails?.isFaceMatched &&
                coApplicantDetails?.alivenessDetails,
            onClick: () => navigate("/liveness-check"),
            isDisabled: !coApplicantDetails?.aadhaarDetails,
            isRejected: false,
        },
    ];

    return {
        navigate,
        termsConditionCheck,
        toggleTermsConditionCheck,
        setShowSuccessPage,
        showSuccessPage,
        setValidationError,
        validationError,
        confirmKycDetails,
        uploadProofMappings,
        digilockerStatusLoading,
        showAadharModal,
        setShowAadharModal,
        uploadAadharState,
        setUploadAadharState,
        openDigiLockerPopup,
    };
};

export default useGetVerifyDetails;
